<template>
  <ClientOnly>
    <div class="account">
      <StoreHeader type="store" />
      <main>
        <slot />
      </main>
    </div>
  </ClientOnly>
</template>

<script setup>
const head = useLocaleHead({
  addDirAttribute: true,
});

const headAndMeta = useHeadAndMeta();

// head is a reactive ref.
useHead(head);

// Static metadata
useHead(headAndMeta);
</script>

<style>
.account {
  @apply font-body min-h-screen flex flex-col dark:bg-gray-900 dark:text-gray-300;
  padding-bottom: var(--headerHeight);
}
</style>
